@import "@/assets/scss/_styles.scss";
























































































.header {
  position: sticky;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid transparent;
  background: $background;

  &.white {
    background: #fff;
    border-bottom: 1px solid $border;
  }

  &-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
  }

  &-logo {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 16px;
    margin-top: -11px;
    font-size: 0;

    &__link {
      display: block;
    }

    &__img {
      width: 76px;
    }
  }

  &-logout {
    &__btn {
      position: absolute;
      z-index: 2;
      top: 50%;
      right: 14px;
      margin-top: -14px;
      width: 16px;
      height: 16px;
      text-align: left;

      img {
        width: 100%;
      }
    }
  }

  &-back {
    &__btn {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 14px;
      margin-top: -14px;
      width: 28px;
      height: 28px;
      text-align: left;

      img {
        width: 100%;
      }
    }
  }

  &-title {
    font-size: 0;
    text-align: center;

    &__txt {
      font: {
        size: 18px;
        weight: bold;
      };
      color: $text;
      letter-spacing: -0.5px;
    }
  }
}
