@import "@/assets/scss/_styles.scss";


























































































@import "src/assets/scss/_styles.scss";
@mixin flexCenter(){
  display: flex;
  align-items: center;
  justify-content: center;
}

.dim{
  @include flexCenter();
  position: fixed;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: $black-fade;
  z-index: 10;
}

.c-confirm {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 40px);
    background: #fff;
    border-radius: 12px;
    z-index: 11;

    &__header {
      padding: 34px 16px 12px;
      @include boxSizing();

      &--title {
        @include font(normal, bold, 18px, $text, 1.17);
        font-stretch: normal;
        letter-spacing: -0.5px;
        text-align: center;
      }

      &--close {
        position: absolute;
        top: 18px;
        right: 22px;

        &:before {
          content:"";
          display: block;
          transform: rotate(45deg);
          width: 1.4px;
          height: 18px;
          background-color: $text-disabled;
        }

        &:after {
          content:"";
          display: block;
          position: absolute;
          top:0;
          transform: rotate(135deg);
          width: 1.4px;
          height: 18px;
          background-color: $text-disabled;
        }
      }
    }

    &__content {
      padding: 0 16px;
      @include font(normal, normal, 16px, $text-sub, 1.31);
      font-stretch: normal;
      letter-spacing: -0.5px;
      text-align: center;
      @include boxSizing();

      &-box {
        padding: 0 16px;
        @include font(normal, normal, 16px, $text-sub, 1.31);
        font-stretch: normal;
        letter-spacing: -0.5px;
        text-align: center;
        @include boxSizing();
      }

      &.large {
        display: flex;
        height: 46px;
        align-items: center;
        justify-content: center;
        color: $text;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.5px;
      }
    }

    &__footer {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      padding: 24px 16px 16px;

      .button {
        flex: 1;
        height: 44px;
        border-radius: 4px;
        border: 1px solid $primary;
        background-color: $primary;
        @include font(normal, bold, 16px, $white, normal);
        text-align: center;

        &.outline-grey {
          @include blockCustomStyle($border-input,$white,$text-sub);
        }
      }
    }
  }

