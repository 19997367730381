$primary: #3285F4;
$light-primary: #e9f2fe;
$error: #ff5a4a;
$background: #eaedf3;

$text: #212121;
$text-sub: #616161;
$text-disabled: #9E9E9E;

$border: #DEE0ED;
$border-input: #BEBEBE;
$border-active: #212121;

$fade: transparent;
$white: #ffffff;
$black: #000000;
$black-fade: rgba(0,0,0,0.5);
$light-grey-e2: #e2e4e7;
$light-grey-f1: #f1f2f4;