/* UI 컴포넌트를 제외한 공통으로 쓰는 스타일 */
.clean {
  &.bg {
    background: $background;
  }

	.member {
		&-text {
      &__main {
        font: {
          size: 18px;
          weight: bold;
        };
        color: $text;
        line-height: 24px;
      }

      &__sub {
        margin-top: 16px;
        font-size: 15px;
        color: #3285f4;
        line-height: 20px;
      }
    }

    &-form {
      margin-top: 24px;
      border-top: 1px solid #e7e8f2;
      padding-top: 24px;

      &__row {
        margin-top: 24px;

        &:first-of-type {
          margin-top: 0;
        }

        &-btn {
          margin-top: 40px;
        }
      }

      &__title {
        display: block;
        margin-bottom: 12px;
        font: {
          size: 15px;
          weight: bold;
        };
        color: $text-sub;
        line-height: 17px;
      }

      &__content {
        position: relative;
      }

      &__input {
        width: 100%;
        height: 44px;
        // border: 1px solid #bebebe;
        border-radius: 4px;
        font-size: 16px;
        // color: #212121;

        &::placeholder {
          color: $text-disabled;
        }
      }

      &__error {
        display: block;
        margin-top: 8px;
        font: {
          size: 12px;
          weight: 500;
        };
        color: $error;
        line-height: 14px;
        letter-spacing: -0.5px;
      }

      &__btn {
        width: 100%;
        border: 1px solid #3285f4;
        border-radius: 4px;

        &-submit {
          height: 50px;
          background: #3285f4;
          font: {
            size: 16px;
            weight: bold;
          };
          color: $white;
          text-align: center;
          line-height: 48px;

          &:disabled {
            @include blockCustomStyle($light-grey-e2, $light-grey-e2, $text-disabled);
          }
        }
      }
    }
	}
}

.roboto {
  font-family: "Roboto", sans-serif;
}


// nprogress 색상 변경
#nprogress .bar {
  background: $primary;
}
