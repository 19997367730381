@import "@/assets/scss/_styles.scss";




















































.container {
  border-top: 8px solid #eaedf3;
}
