@charset "utf-8";

@for $i from 0 through 200 {
  .pd-x-#{$i} {
    padding-left: 1px * $i;
    padding-right: 1px * $i;
  }
  .pd-y-#{$i} {
    padding-top: 1px * $i;
    padding-bottom: 1px * $i;
  }
  .pd-t-#{$i} {
    padding-top: 1px * $i;
  }
  .pd-r-#{$i} {
    padding-right: 1px * $i;
  }
  .pd-b-#{$i} {
    padding-bottom: 1px * $i;
  }
  .pd-l-#{$i} {
    padding-left: 1px * $i;
  }
  .mg-x-#{$i} {
    margin-left: 1px * $i;
    margin-right: 1px * $i;
  }
  .mg-y-#{$i} {
    margin-top: 1px * $i;
    margin-bottom: 1px * $i;
  }
  .mg-t-#{$i} {
    margin-top: 1px * $i;
  }
  .mg-r-#{$i} {
    margin-right: 1px * $i;
  }
  .mg-b-#{$i} {
    margin-bottom: 1px * $i;
  }
  .mg-l-#{$i} {
    margin-left: 1px * $i;
  }
}

@for $i from 1 through 100 {
  .width-#{$i * 10} {
    width: 1px * ($i * 10);
  }
  .height-#{$i * 10} {
    height: 1px * ($i * 10);
  }
}
