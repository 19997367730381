@import "@/assets/scss/_styles.scss";




















.footer {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;

  &__img {
    width: 90px;
    height: 30px;

    img {
      width: 100%;
    }
  }
}
