@charset "UTF-8";

.blind {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}

@mixin line($lineCount: 1) {
  /* 사용법 : @include line(라인수) */
  @if ($lineCount==1) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  } @else {
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
      /* autoprefixer: on */
      display: block;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      -webkit-line-clamp: $lineCount;
  }
}

/* ========== mixin ========== */

/* flex :: center */
@mixin flexCenter(){
  display: flex;
  align-items: center;
  justify-content: center;
}

/* width :: element width size */
@mixin blockSize($size){
  /* 사용법 : @include widthSize(size)*/
  @if $size == "full" {
    width: 100%;
    height: 44px;
  } @else if $size == "medium" {
    width: 50%;
    height: 44px;
  }
}

/* border , background , font color style */
@mixin blockCustomStyle($borderColor, $backgroundColor, $color){
  border-color: $borderColor;
  background-color: $backgroundColor;
  color: $color;
}

/* font */
@mixin font($style, $weight, $size, $color, $lineHeight){
  font: {
    style: $style;
    weight: $weight;
    size: $size;
  }
  color: $color;
  line-height: $lineHeight;
}

/* box sizing */
@mixin boxSizing(){
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@import "./_reset.scss";
@import "./_colors.scss";
@import "./_vw.scss";
@import "./_loop.scss";
@import "./common.scss";